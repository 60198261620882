class CENTRAPAY {
  constructor() {
    this.API_URL = "https://service.centrapay.com";
    this.CENTRAPAY_KEY = process.env.VUE_APP_CENTRAPAY_KEY;
    this.CENTRAPAY_CONFIGID = process.env.VUE_APP_CENTRAPAY_CONFIGID;
    this.headers =
      {
	"Content-Type": "application/json",
	"X-Api-Key": this.CENTRAPAY_KEY
      };
  }
  
  async get(url = "") {
    const response = await fetch(this.API_URL + url, {
      method: "get",
      headers: this.headers,
    });
    return response.json(); // parses JSON response into native JavaScript objects
  }
  
  async post(url = "", data = {}) {
    const response = await fetch(this.API_URL + url, {
      method: "post",
      headers: this.headers,
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    });
    
    if (response.status != 200 && response.status != 201) {
      throw "Server error: Error code is " + response.status;
    }
    
    return response.json(); // parses JSON response into native JavaScript objects
  }
  
  async paymentRequest(total, lineItems = []) {
    let data = {
      "configId": this.CENTRAPAY_CONFIGID,
      "value": {
	"amount": (parseInt(total*100)).toString(),
	"currency": "NZD"
      },
      "lineItems": lineItems
    }
    return await this.post("/api/payment-requests", data);
  }

  async paymentStatus(id) {
    /***let data = {"assetType": "centrapay.nzd.test",
	"transactionId": id
	};***/
    let response = await this.get(this.getQRCodeURL(id));
    if (response.status == 502) {
      await this.paymentStatus(id);
    } else if (response.status != 200) {
      await new Promise(resolve => setTimeout(resolve, 1000));
      // console.log(response);
      await this.paymentStatus(id);
    } else {
      let message = await response.text()
      return message;
    }
  }

  getQRCodeURL(paymentRequestId) {
    return "/api/payment-requests/" + paymentRequestId;
  }
}

const centrapay = new CENTRAPAY();
export { centrapay };
