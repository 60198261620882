<template>
  <button v-if="!inventory" :class="isSelected ? 'selected' : ''" @click="addModifier">
    <h2>{{ modifier.name }}</h2>
    <div class="desc">
      <div v-if="modifier.desc" v-html="modifier.desc"></div>
      <p v-if="modifier.price">${{ modifier.price }}</p>
    </div>
  </button>
  <button v-if="inventory && modifier.quantity > 5" :class="isSelected ? 'selected' : ''" @click="addModifier">
    <h2>{{ modifier.name }}</h2>
    <div class="desc">
      <div v-if="modifier.desc" v-html="modifier.desc"></div>
      <p v-if="modifier.price">${{ modifier.price }}</p>
    </div>
  </button>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ItemModal",
  props: ["modifier", "modifierSet", "selectedModifiersSets"],
  emits: ["addModifier"],
  data() {
    return {
      isSelected: this.selected,
      price: this.modifier.price,
    };
  },
  methods: {
    addModifier() {
      this.isSelected = !this.isSelected;
      this.$emit("addModifier", {
        modifier: this.modifier,
        modifier_set: this.modifierSet,
        selected: this.isSelected,
      });
    },
  },
  mounted() {
    this.isSelected = this.selected;
  },
  computed: {
    ...mapGetters({
    }),
    selected() {
      return Boolean(
        this.selectedModifiersSets &&
          this.modifierSet &&
          this.modifierSet._id &&
          this.selectedModifiersSets[this.modifierSet._id] &&
          this.selectedModifiersSets[this.modifierSet._id].find((i) => i.name === this.modifier.name)
      );
    },
    inventory() {
      return this.modifierSet.rules && this.modifierSet.rules.inventory;
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  margin: 0.2rem;
  padding: 0.2rem;
  font-size: 0.8rem;
  text-transform: capitalize;
  background-color: var(--primary-colour);
  color: var(--text-colour);
  min-height: 4rem;
}
.non-selectable {
  opacity: 0.25;
  cursor: default;
}

h2 {
  margin: 0;
}

.desc p {
  margin: 0;
}

.selected {
  background-color: var(--action-colour) !important;
  color: var(--hover-colour);
}

.desc {
  display: flex;
  align-items: center;
  justify-content: center;
  //flex-direction: column;
  gap: 0rem;
}
</style>
