<template>
  <teleport to="body">
    <div v-if="openModal" class="modal" @click.self.prevent="close">
      <div class="product">
        <font-awesome-icon icon="xmark" class="exit" @click="close" />
        <div id="product_header">
          <div class="title">
            <span class="subtitle">
              <h2>{{ currentProduct.name }}</h2>
              <h2>${{ productLoaded.price + modifierPrices }}</h2>
            </span>
            <span class="desc" v-html="currentProduct.description"></span>
            <span>
              <h3>Allergens:</h3>
              <div v-for="allergen in allAllergens" :key="allergen">
                <p>- {{ allergen }}</p>
              </div>
            </span>
          </div>

          <div v-if="currentProduct.images" class="images">
            <img v-for="image in currentProduct.images.slice(0, 4)" :key="image" :src="image" />
          </div>
        </div>
        <div v-if="currentProduct && selectedModifiersSets">
          <button
            :disabled="!valid"
            :class="(!valid ? 'non-selectable' : '') + ' addToCart fr'"
            @click="
              addToCartWrapper({
                id: currentProduct._id,
                modifiers: selectedModifiersSets,
                price: currentProduct.price,
              });
              openModal = false;
            "
          >
            <font-awesome-icon icon="plus" />
            Add to Cart
          </button>

          <div v-if="showValidations">
            <ul :key="validation.name" v-for="validation in validations">
              <!-- <h5>{{ validation.name }}</h5> -->
              <li :key="validation.name + '-' + rule.name" v-for="rule in validation.value">
                <span>{{ rule.name }} - {{ rule.value }}</span>
              </li>
            </ul>
          </div>
          <div v-if="renderComponent">
            <ul class="modifiers" v-bind:key="modifier_set" v-for="modifier_set in modifier_sets">
              <div class="modifier-heading">
                <h3>{{ modifier_set.name }}</h3>
                <h4 class="warning" v-if="modifier_set.rules.required && !valid">Required*</h4>
              </div>
              <div class="modifiers-layout" v-if="!currentProduct.overrideOneMod">
                <ItemModalButton
                  :modifier="modifier"
                  :selectedModifiersSets="selectedModifiersSets"
                  :modifierSet="modifier_set"
                  @addModifier="addModifier"
                  v-bind:key="modifier"
                  v-for="modifier in modifier_set.modifiers.filter((mod) => mod.active)"
                />
              </div>
              <div class="modifiers-layout" v-if="currentProduct.overrideOneMod">
                <ItemQuantitySetter
                  :modifier="modifier"
                  :selectedModifiersSets="selectedModifiersSets"
                  :modifierSet="modifier_set"
                  @addModifier="addModifier"
                  @addOverrideMod="addOverrideMod"
                  @removeOverrideMod="removeOverrideMod"
                  v-bind:key="modifier"
                  v-for="modifier in modifier_set.modifiers.filter((mod) => mod.active)"
                />
              </div>
            </ul>
            <span style="height: 100px"></span>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import ItemModalButton from "@/components/Store/ItemModalButton.vue";
import ItemQuantitySetter from "@/components/Store/ItemQuantitySetter.vue";
import { mapActions } from "vuex";
import { reactive } from "vue";
import { mapGetters } from "vuex";

export default {
  name: "ItemModal",
  emits: ["closeItemModal"],
  props: {
    open: Boolean,
    currentProduct: Object,
    lineItem: {
      type: Object,
      required: false,
      default: null,
    },
  },
  components: { ItemModalButton, ItemQuantitySetter },
  data() {
    return {
      valid: false,
      openModal: false,
      validations: [],
      showValidations: false,
      quantity: 1,
      renderComponent: true,
      modifierPrices: 0,
      productLoaded: {},
      allAllergens: [],
    };
  },
  methods: {
    combinedAllergens() {
      let a = [];
      if ("allergens" in this.currentProduct) {
        this.currentProduct.allergens.map((allergen) => (allergen.contains ? a.push(allergen.name) : null));
      }
      if (this.selectedModifiersSets) {
        for (let set in this.selectedModifiersSets) {
          for (let mod in this.selectedModifiersSets[set]) {
            if ("allergens" in this.selectedModifiersSets[set][mod]) {
              this.selectedModifiersSets[set][mod].allergens.map((allergen) => {
                if (allergen.contains) {
                  a.push(allergen.name);
                }
              });
            }
          }
        }
      }
      this.allAllergens = Array.from(new Set(a));
    },

    close() {
      this.openModal = !this.openModal;
      this.selectedModifiersSets = {};
      this.$router.push("/web/" + this.$route.params.id);
    },
    async addToCartWrapper(currentProduct) {
      /* first remove the existing item */
      this.selectedModifiersSets = {};

      if (this.lineItem) {
        await this.changeLineItemQuantity({
          productHash: this.lineItem.hash,
          delta: this.lineItem.quantity * -1,
        });
        currentProduct.hash = this.lineItem.hash;
      }
      this.addToCart(currentProduct);
      this.close();

      if (Object.keys(currentProduct.modifiers).length == 0) {
        this.close();
      }

      this.$notify({
        text: "Item added to cart",
        type: "success",
        duration: 1500,
      });

      if (!this.lineItem) {
        this.changeCartDelta(currentProduct, 1);
      } else {
        await this.changeLineItemQuantity({
          productHash: this.lineItem.hash,
          delta: (this.lineItem.quantity - 1) * 1,
        });
      }
      this.$emit("closeItemModal");
      this.close();
    },
    forceRerender() {
      // Removing my-component from the DOM
      this.renderComponent = false;

      this.$nextTick(() => {
        // Adding the component back in
        this.renderComponent = true;
      });
    },
    changeCartDelta(currentProduct, delta) {
      Object.keys(currentProduct.modifiers).forEach((modifierset) => {
        let foundModifierSet = this.modifier_sets.find((m) => m._id === modifierset);
        if (foundModifierSet && foundModifierSet.rules) {
          if (foundModifierSet.rules.inventory) {
            /* send to web socket */
            currentProduct.modifiers[modifierset].forEach((modifierDeltaChange) => {
              //console.log("push to web socket");
              this.channel.push("modifier:set:delta", {
                modifierset: modifierset,
                modifier: modifierDeltaChange,
                delta: delta * -1,
              });
            });
          }
        }
      });
    },
    calculatePrice() {
      this.modifierPrices = 0;
      for (let set in this.selectedModifiersSets) {
        for (let mod in this.selectedModifiersSets[set]) {
          this.modifierPrices += this.selectedModifiersSets[set][mod].price;
        }
      }
    },
    ...mapActions(["addToCart", "changeLineItemQuantity"]),
    validation() {
      this.combinedAllergens();

      /* validations should be an array where every value is 'true' */

      /* every value, represents a modifier set, but they themselves solve be an array, because a single modifier set may have multiple */
      /* validations required and 'all' must be true */
      if (!this.modifier_sets) {
        return;
      }

      if (this.currentProduct.overrideOneMod) {
        this.valid = true;
        return;
      }
      this.validations = this.modifier_sets.map((set) => {
        let set_validations = [];

        /* is this modifier set 'required' */
        if (set && set.rules && set.rules.required) {
          if (set._id in this.selectedModifiersSets) {
            /* do we have an object in this set meeting the 'required' requirement */
            if (this.selectedModifiersSets[set._id] && this.selectedModifiersSets[set._id].length > 0) {
              set_validations.push({ name: "required", value: true });
            } else {
              set_validations.push({ name: "required", value: false });
            }
          } else {
            set_validations.push({ name: "required", value: false });
          }
        }

        /* is this modifier set 'one-only', e.g. bagel-type */
        if (set && set.rules && set.rules.oneonly) {
          if (set._id in this.selectedModifiersSets) {
            /* do we have an object in this set meeting the 'required requirement */
            if (this.selectedModifiersSets[set._id] && this.selectedModifiersSets[set._id].length === 1) {
              set_validations.push({ name: "oneonly", value: true });
            } else {
              set_validations.push({ name: "oneonly", value: false });
            }
          }
          // else {
          //   set_validations.push({name: 'oneonly', value: false});
          // }
        }

        /* this is test code where inventory is being used as a must include minimum of (e.g. 3 requirements) */

        if (set && set.rules && set.rules.inventory) {
          set_validations.push({ name: "inventory", value: true });

          // if ( ( set._id in this.selectedModifiersSets ) ) {
          //   /* do we have an object in this set meeting the 'requivar(--warning-colour)' requirement */
          //   if ( this.selectedModifiersSets[set._id].length >= 5 ) {
          //     set_validations.push({name: 'inventory', value: true});
          //   }
          //   else {
          //     set_validations.push({name: 'inventory', value: false});
          //   }
          // }
          // else {
          //   set_validations.push({name: 'inventory', value: false});
          // }
        }
        //
        /* no defined rules - therefore validation passes */

        if (set && set.rules) {
          set_validations.push({ name: "no-rules", value: true });
        }

        return { name: set._id, value: set_validations };
      });
      if (this.validations.length === 0) {
        this.valid = true;
      } else {
        //this.showValidations = true;
        let all_valid = this.validations.every((set) => {
          /* fail the rule if a single modifier returns false (or is empty) - the logic rules should always be true if the rules are optional */
          if (set.value.length === 0) {
            return false;
          }

          return set.value.every((rule) => {
            return rule.value === true;
          });
        });

        this.valid = all_valid;
      }
    },
    addOverrideMod(v) {
      const modifier = { ...v.modifier };
      let modifier_set = v.modifier_set;
      if (!this.selectedModifiersSets[modifier_set._id]) {
        this.selectedModifiersSets[modifier_set._id] = [];
      }
      this.selectedModifiersSets[modifier_set._id].length == 0 ? (modifier.price = v.modifier.price) : (modifier.price = this.productLoaded.price + v.modifier.price);
      this.selectedModifiersSets[modifier_set._id].push({ ...modifier });
      this.calculatePrice();
      ////console.log(modifier.price, v.modifier.price, this.productLoaded.price);
    },
    removeOverrideMod(v) {
      let modifier = v.modifier;
      let modifier_set = v.modifier_set;
      this.selectedModifiersSets[modifier_set._id].pop(modifier);
      //console.log(this.selectedModifiersSets);
      this.calculatePrice();
    },
    addModifier(v) {
      let modifier = v.modifier.name;
      let modifier_set = v.modifier_set;
      let selected = v.selected;
      if (modifier_set.rules.oneonly && this.selectedModifiersSets[modifier_set._id]) {
        for (var mod in this.selectedModifiersSets) {
          if (mod === modifier_set._id) {
            this.selectedModifiersSets[mod] = [];
          }
        }
        this.forceRerender();
      }

      if (selected) {
        if (!(modifier_set._id in this.selectedModifiersSets)) {
          this.selectedModifiersSets[modifier_set._id] = [];
        }

        /* toggle */
        if (
          this.selectedModifiersSets[modifier_set._id].find((m) => {
            return m.name === modifier;
          })
        ) {
          this.selectedModifiersSets[modifier_set._id] = this.selectedModifiersSets[modifier_set._id].filter((mod) => {
            return modifier !== mod;
          });
        } else {
          this.selectedModifiersSets[modifier_set._id].push(v.modifier);
        }
      } else {
        if (this.selectedModifiersSets[modifier_set._id].find((m) => m.name === modifier)) {
          this.selectedModifiersSets[modifier_set._id] = this.selectedModifiersSets[modifier_set._id].filter((mod) => {
            return modifier !== mod.name;
          });
          //console.log(
          // ;
        }
      }
      // if(modifier_set.rules.oneonly){

      // }

      this.modifierPrices = 0;
      this.calculatePrice();
      this.validation();
    },
  },
  mounted() {},
  computed: {
    ...mapGetters({
      /* we want all modifier sets (full doc) - there is an es6 function that does this - what is it? */
    }),
    modifier_sets() {
      if (!this.currentProduct) {
        return [];
      }

      let sets = [...this.currentProduct.modifiersets];
      let modifier_sets_docs = this.$store.getters.modifiers(sets);

      return modifier_sets_docs.sort((a) => {
        if (a && a.rules && a.rules.required) {
          return -1;
        }
        return 0;
      });
    },
  },
  setup() {
    const selectedModifiersSets = reactive({});

    return {
      selectedModifiersSets,
    };
  },
  watch: {
    // currentProduct() {
    //   this.combinedAllergens();
    // },

    open() {
      if (this.modifier_sets.length === 0) {
        this.addToCartWrapper({
          id: this.currentProduct._id,
          modifiers: this.selectedModifiersSets,
          price: this.productPrice,
        });

        this.close();
        return;
      }

      this.selectedModifiersSets.value = {};

      /* if a lineItem has been selected - reset the modifiers */
      if (this.lineItem) {
        Object.keys(this.lineItem.modifiers).forEach((k) => {
          this.lineItem.modifiers[k].map((modifier) => {
            if (!(k in this.selectedModifiersSets)) {
              this.selectedModifiersSets[k] = [];
            }

            this.selectedModifiersSets[k].push(modifier);
          });
        });
      }
      this.productLoaded = { ...this.currentProduct };
      this.modifierPrices = 0;
      this.valid = false;
      this.openModal = true;
      this.validation();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "public/wrapper";

.quantity-link {
  font-size: 1rem;
  font-weight: 400;
  text-decoration: none;
  margin-bottom: 1rem;
  margin-top: 1rem;
  display: block;
  button {
    padding: 0.4rem;
    background-color: var(--secondary-colour);
    color: var(--text-colour);
  }
}
.non-selectable {
  // opacity: 0.5;
  background-color: color-mix(in srgb, var(--action-colour) 100%, black 100%);
}
#product_header {
  display: flex;
  //flex-direction: column;
  flex: 1 1;
  padding-bottom: 1rem;
  .title {
    flex: 1;
    flex-direction: column;
    padding-left: 2rem;
    .subtitle {
      margin: 2rem 0 1rem 0;
    }
    .desc {
      margin: 0 0 1rem 0;
    }
    h3 {
      margin: 0.25rem;
    }
    p {
      margin: 0;
    }
  }
  .images {
    padding-right: 2rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    img {
      width: 8rem;
    }
  }
}
@media only screen and (orientation: portrait) {
  #product_header {
    flex-direction: column-reverse;
    .images {
      padding: 0;
      margin: auto;
      row-gap: 0;
      width: min-content;
    }
  }
}
</style>
