<template>
      <div
        v-if="openConfirm"
        class="modal"
        @click.self.prevent="
          $emit('confirmModal')
        "
      >
        <div class="wrapper">
          <div class="content">
            <h3 class="title">{{ message }}?</h3>
            <div class="actions">
              <button class="action cancel" @click.prevent.stop="$emit('confirmModal')">Cancel</button>
              <button class="action primary" @click.prevent.stop="$emit('confirmModal', true)">Confirm</button>
            </div>
          </div>
        </div>
      </div>
</template>

<script>
export default {
  name: "ConfirmModal",
  props: ["open", "message"],
  emits: [ "confirmModal"],
  data() {
    return {
      openConfirm: false,
    };
  },
  watch: {
    open() {
      this.openConfirm = this.open;
    },
  },
};


</script>

<style scoped lang="scss">
@import "public/wrapper";
</style>
