<template lang="en">

  <div @click.stop.prevent="payapRequest" class="centrapay"> 
    <div class="centrapay_center">
      <img src="/centrapay_logo_orange.svg" />
    </div>
    <div class="centrapay_options">
      <button class="pay-btn" >PAYA<span class="flip">P</span></button>
    </div>
  </div>

	<div v-if="payapQrcodeShow" class="centrapay_right" >
    <h1 @click.stop.prevent="payapQrcodeShow = !payapQrcodeShow">x</h1>
    <qr-code id="qr"
        :contents= payURL
        position-ring-color="var(--text-colour)"
        position-center-color="var(--centrapay-orange)"
        module-color="var(--text-colour)">
        <!-- eslint-disable-next-line -->
      <img v-if="payapQrcodeShow" id="qr-img" src="/centrapay_symbol_orange.svg" slot="icon"/>
    </qr-code>
	</div>

</template>

<script>
import { centrapay } from "@/helpers/centrapay";

export default {
  name: "CentrapayQRCode",
  props: {
    lineItems: {
      type: Array,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      centrapay: "",
      payapQrcodeShow: false,
      payURL: "https://beammeup.nz",
    };
  },

  methods: {
    async payapRequest() {
      let response = await centrapay.paymentRequest(this.total, this.lineItems);
      // console.log(response);
      if (this.payapQrcodeShow === false) {
        this.payURL = response.url;
        this.payapQrcodeShow = !this.payapQrcodeShow;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.centrapay_right {
  height: 100%;
  width: 100%;
  background-color: var(--primary-colour);
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  margin: auto;

  h1 {
    margin: 1rem auto;
    background-color: var(--text-colour);
    font-family: "Roboto", sans-serif;
    text-transform: lowercase;
    font-weight: 900;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    color: var(--centrapay-orange);
    // text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.3);
  }

  #qr-img {
    width: 100%;
    display: flex;
    padding: 0;
  }

  #qr {
    width: 70%;
    background-color: var(--primary-colour);
    margin: auto;
  }
}

.centrapay {
  display: flex;
  // justify-content: center;
  margin: 1rem auto;
  flex-direction: column;
  align-items: center;
  // border: 1px solid var(--text-colour);
  box-shadow: var(--box-shadow);
  border-radius: 0.5rem;
  background-color: var(--secondary-colour);
  cursor: pointer;
  .centrapay_center {
    margin: 0.5rem 0.5rem auto;
    padding: 0.25rem 0.5rem;
    width: 50%;
    max-width: 30vw;
  }
  .centrapay_options {
    display: flex;
    justify-content: flex-start;
    padding: 0.5rem;
    width: 100%;
    .pay-btn {
      background-color: var(--primary-colour);
      color: var(--text-colour);
      border: none;
      margin-top: 0.5rem;
      .flip {
        display: inline-block;
        transform: scale(-1, 1);
      }
    }
  }
}
</style>
