<template>
  <div class="header"><Header /></div>
  <div>
    <Menu :collapseMenu="$route.path === '/store'" />
    <div id="content"><slot /></div>
  </div>
</template>

<script>
import Menu from "@/components/_layouts/Menu";
import Header from "@/components/_layouts/Header";
import { mapGetters } from "vuex";

export default {
  components: { Menu, Header },
  name: "Authenticated",
  data(){
    return {
    }
  },
  computed: {
    ...mapGetters({
      loading: "loading",
      socketConnected: "socketConnected",
      getHours: "getHours",
    }),
    thisDate(){
      return this.currentTime.toLocaleDateString();
    }
  },
  watch: {
    // '': {
    //     handler() {
    //       //console.log('State change');
    //     }
    // }
  },
  methods: {


  },
  async mounted() {
    // console.log("hours", await this.getHours);
    // console.log("date", Date.now());
    // console.log(this.convertHoursMinutes("14:30"));
    // console.log(this.checkOpen(this.convertHoursMinutes("16:00"), this.convertHoursMinutes("16:30")));
    // console.log(this.days.at(this.currentTime.getDay()));

    // console.log(normalOpen);

    // console.log(this.getHours.holiday.every(h => h.date == "01/01/2024"));
  },
};
</script>

<style scoped lang="scss">
.outer-container {
  display: flex;
  flex-direction: row;
  flex: 1;
  min-height: calc(100vh);

  #content {
    width: 100vw;
    margin-left: 5rem;
  }
}

.header {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--primary-colour);
  color: var(--text-colour);
  justify-content: center;
}

@media only screen and (orientation: portrait) {
  .outer-container {
    flex-direction: column;

    #content {
      margin-left: 0rem;
    }
  }

  #menu {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
