<template>
  <teleport to="body">
    <div
      v-if="openModal"
      class="modal"
      @click.self.prevent="
        openModal = !openModal;
        openNotesModal = false;
      "
    >
      <div class="outer">
        <font-awesome-icon icon="xmark" class="exit" @click="openModal = !openModal" />
        <p v-if="!futurePickup" class="time bump-right">Pickup time: ~ 10 mins</p>
        <form @submit.stop.prevent>
          <div class="details">
            <!-- <h2 class="remaining">
            <span class="label" v-if="remaining >= 0">Balance</span>
            <span class="label" v-if="remaining < 0">Change for Customer</span>
            <span class="value">{{ '$' + remaining.toFixed(2) }}</span>
            </h2> -->
            <ul class="log" v-if="showLog">
              <li v-bind:key="idx" v-for="(payment, idx) in payments">{{ payment.type }} - {{ "$" + payment.value.toFixed(2) }}</li>
            </ul>

            <div v-if="user.fname" class="logged-in">
              <div class="logged-in-user">
                <div class="user-info">
                  <div>{{ user.fname }} {{ user.lname }}</div>
                  <div>{{ user.phone }}</div>
                  <div>{{ user.email }}</div>
                </div>
                <div class="notes">
                  <AllergenList @allergen-changed="addAllergen" />
                </div>
              </div>
            </div>
            <div class="notes slide" v-if="!user.fname">
              <div>
                <h4 v-if="siteSettings && siteSettings.nameRequired">Name</h4>
                <input v-if="siteSettings && siteSettings.nameRequired" type="text" v-model="name" placeholder="Customer Name..." />
              </div>
              <div>
                <AllergenList @allergen-changed="addAllergen" />
              </div>
              <div class="cell">
                <h4>Cell Phone</h4>
                <input type="tel" v-model="cell" placeholder="Cell Phone..." />
              </div>
              <div class="email">
                <h4>E-mail</h4>

                <input type="text" v-model="email" placeholder="Customer Email..." />
              </div>
            </div>
            <h4 v-if="futurePickup">Pick Up Location {{ location.toUpperCase() }}</h4>
            <div v-if="futurePickup" class="location">
              <select v-model="location">
                <option value="" disabled>Please select one</option>

                <option v-for="(shop, value) in physicalShops" v-bind:key="value" :value="value"> {{ value.toUpperCase() }} - {{ shop.address }} </option>
              </select>
            </div>

            <div v-if="futurePickup">
              <h4>Date of Pick Up</h4>
              <input type="date" v-model="date" :min="futureDate" max="2099-01-01" />
            </div>

            <div v-if="errors">
              <div class="errors" v-for="(error, index) in errors" :key="index">
                {{ error }}
              </div>
            </div>
            <div class="stripe-logo" @click.stop.prevent="getIntent">
              <p>Pay with</p>
              <svg viewBox="0 0 60 25" xmlns="http://www.w3.org/2000/svg" width="60" height="25" class="UserLogo variant-- ">
                <title>Stripe logo</title>
                <path
                  fill="var(--text-colour)"
                  d="M59.64 14.28h-8.06c.19 1.93 1.6 2.55 3.2 2.55 1.64 0 2.96-.37 4.05-.95v3.32a8.33 8.33 0 0 1-4.56 1.1c-4.01 0-6.83-2.5-6.83-7.48 0-4.19 2.39-7.52 6.3-7.52 3.92 0 5.96 3.28 5.96 7.5 0 .4-.04 1.26-.06 1.48zm-5.92-5.62c-1.03 0-2.17.73-2.17 2.58h4.25c0-1.85-1.07-2.58-2.08-2.58zM40.95 20.3c-1.44 0-2.32-.6-2.9-1.04l-.02 4.63-4.12.87V5.57h3.76l.08 1.02a4.7 4.7 0 0 1 3.23-1.29c2.9 0 5.62 2.6 5.62 7.4 0 5.23-2.7 7.6-5.65 7.6zM40 8.95c-.95 0-1.54.34-1.97.81l.02 6.12c.4.44.98.78 1.95.78 1.52 0 2.54-1.65 2.54-3.87 0-2.15-1.04-3.84-2.54-3.84zM28.24 5.57h4.13v14.44h-4.13V5.57zm0-4.7L32.37 0v3.36l-4.13.88V.88zm-4.32 9.35v9.79H19.8V5.57h3.7l.12 1.22c1-1.77 3.07-1.41 3.62-1.22v3.79c-.52-.17-2.29-.43-3.32.86zm-8.55 4.72c0 2.43 2.6 1.68 3.12 1.46v3.36c-.55.3-1.54.54-2.89.54a4.15 4.15 0 0 1-4.27-4.24l.01-13.17 4.02-.86v3.54h3.14V9.1h-3.13v5.85zm-4.91.7c0 2.97-2.31 4.66-5.73 4.66a11.2 11.2 0 0 1-4.46-.93v-3.93c1.38.75 3.1 1.31 4.46 1.31.92 0 1.53-.24 1.53-1C6.26 13.77 0 14.51 0 9.95 0 7.04 2.28 5.3 5.62 5.3c1.36 0 2.72.2 4.09.75v3.88a9.23 9.23 0 0 0-4.1-1.06c-.86 0-1.44.25-1.44.9 0 1.85 6.29.97 6.29 5.88z"
                  fill-rule="evenodd"
                ></path>
              </svg>
            </div>
            <div v-if="siteSettings && siteSettings.centrapay_pay" class="centrapay">
              <CentrapayQRCode v-if="!intentLoaded" :lineItems="lineItems" :total="total" />
            </div>

            <div class="stripe">
              <div class="payment-section">
                <form @submit.prevent="handlePayment" id="payment-form">
                  <div id="payment-element">
                    <!--Stripe.js injects the Payment Element-->
                  </div>
                  <button v-if="intentLoaded" id="submit">
                    <div class="spinner hidden" id="spinner"></div>
                    <span id="button-text">Pay now</span>
                  </button>
                  <div id="payment-message" class="hidden"></div>
                  <div v-if="stripeErrors">
                    <div class="errors" v-for="(error, index) in stripeErrors" :key="index">
                      {{ error }}
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div v-if="paymentProccessing" class="svg-spinner">
              <LoadingSpinner spinner-message="Processing Payment..." />
            </div>
          </div>
        </form>
      </div>
    </div>
  </teleport>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
//import {bagelSocket} from "@/helpers/socket";
//import { ref } from "vue";
import parsePhoneNumber, { validatePhoneNumberLength } from "libphonenumber-js";
import { api } from "@/helpers/api";
import LoadingSpinner from "@/components/_layouts/LoadingSpinner";
import AllergenList from "@/components/_layouts/AllergenList.vue";
// import Cart from "./Cart.vue";
//import VueDatePicker from "@vuepic/vue-datepicker";
//import "@vuepic/vue-datepicker/dist/main.css";
import CentrapayQRCode from "@/components/Store/CentrapayQRCode.vue";

export default {
  name: "CheckoutModal",
  props: ["open", "close"],
  emits: ["showWebOrderModal"],
  components: { LoadingSpinner, CentrapayQRCode, AllergenList },
  data() {
    return {
      stripe: null,
      elements: null,
      intentLoaded: false,
      paymentProccessing: false,
      year: new Date().getFullYear(),
      dates: ["2023/12/25", "2023/12/26", "2024/1/1", "2024/1/2"],
      openModal: false,
      openSplitEftpos: false,
      openDiscountModal: false,
      openNotesModal: false,
      showLog: false,
      name: null,
      notes: null,
      email: null,
      email_receipt: false,
      cell: "",
      location: "",
      shops: JSON.parse(window.localStorage.getItem("shops")),
      errors: [],
      stripeErrors: [],
      delivery: "",
      when: null,
      splitOrderCurrentTotal: null,
      paymentStatus: "pending", // pending -> partial -> full -> post -> complete (orders can be appending to, e.g. dessert),
      moneyShow: false,
      eftposShow: false,
      accountShow: false,
      voucherShow: false,
      customAmount: 0,
      changeDue: 0,
      searches: [],
      searchQuery: "",
      voucherSearchQuery: "",
      eftposBusy: false,
      eftposSig: false,
      lineItems: [],
      futureDate: new Date(new Date().setDate(new Date().getDate() + 2))
        .toLocaleString()
        .split(",")[0]
        .split("/")
        .reverse()
        .join("-"),
      inputDate: new Date(new Date().setDate(new Date().getDate() + 3))
        .toLocaleString()
        .split(",")[0]
        .split("/")
        .reverse()
        .join("-"),
      date: this.inputDate,
    };
  },
  /*** setup() {
    // In case of a range picker, you'll receive [Date, Date]
    const format = (date) => {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `Selected date is ${day}/${month}/${year}`;
    };
    const endDate = () => {
      return new Date("25/12/2022");
    };

    const handleDate = (modelData) => {
      date.value = modelData;
      //console.log(date);
      //date.value = intlDateObj.format(date.value);
      // do something else with the data
    };
    return {
      date,
      format,
      endDate,
      handleDate,
    };
  },***/

  created() {
    if (window.localStorage.getItem("futurePickup")) {
      this.$store.dispatch("setFuturePickup", true);
    }

    //bagelSocket.setUpChannel(this);
    //this.channel = this.$.appContext.config.globalProperties.channel;
  },
  async mounted() {
    const stripeKey = process.env.VUE_APP_STRIPE_LIVE;
    // estlint-disable-next-line
    this.stripe = window.Stripe(stripeKey);
    this.stripe.api_version = "2024-10-28.acacia";
  },
  methods: {
    ...mapActions(["addToCart", "increaseCartQuantity", "decreaseCartQuantity", "addPayment", "clearCart", "clearPayments", "updateWebOrder"]),

    addAllergen(allergens) {
      let allergies = [];
      allergens.map((allergen) => {
        if (allergen.contains) {
          if (!allergies.includes(allergen.name)) {
            allergies.push(allergen.name);
          }
        } else if (!allergen.contains) {
          this.allergens = allergies.filter((all) => all !== allergen.name);
        }
      });
      let allergyNote = allergies.join(", ");
      this.notes = "Allergies: " + allergyNote;
    },

    loadCentrapay() {
      // console.log(this.products);
      this.lineItems = this.products.map((prod) => {
        return {
          name: prod.name,
          sku: prod.id,
          price: parseInt(prod.quantity * prod.price * 100).toString(),
          qty: prod.quantity.toString(),
          tax: "15.0",
        };
      });
    },

    dateValidation() {
      let d = new Date(this.futureDate)
      d.setHours(0, 0, 0, 0);
      let twoDays = Math.floor(d.getTime() / 1000);
      let selectedDate = Math.floor(new Date(this.date).getTime() / 1000);

      if (twoDays > selectedDate) {
        this.errors.push("Pickup date must be at least 2 days in the future");
      }
    },

    getIntent() {
      this.transactionValidation();
      if (this.errors.length === 0) {
        var order_data = {
          amount: parseInt(this.total * 100),
          currency: "nzd",
        };
        //console.log("amount:", this.total * 100);

        this.channel.push("stripe:paymentIntent", { order: order_data }).receive("ok", (intent) => {
          this.updateIntent(intent.reply);
          this.intentLoaded = true;
        });
        const close = document.querySelector("div.slide");
        close.classList.add("close");
        close.classList.remove("notes");
      }
    },
    updateIntent(clientSecret) {
      const getThemeConfig = () => {
        const isDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches;

        return {
          theme: "stripe",
          variables: {
            colorPrimary: "#3bafda",
            colorBackground: "#fafaff",
            colorText: "#d8d5d5",
            borderRadius: "0.5rem",
          },
          rules: {
            ".Input": {
              padding: "1rem",
              fontSize: "1.5rem",
              color: "#2e2f35",
            },
            ".Label": {
              fontWeight: "bold",
              margin: "0.5rem 0 0.5rem 1rem",
              color: isDarkMode ? "#d8d5d5" : "#2e2f35",
            },
          },
        };
      };

      const appearance = getThemeConfig();

      const paymentElementOptions = {
        layout: "tabs",
      };
      this.elements = this.stripe.elements({ appearance, clientSecret });
      this.intentLoaded = true;
      console.log("intent received");
      const paymentElement = this.elements.create("payment", paymentElementOptions);
      paymentElement.mount(document.querySelector("#payment-element"));

      window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", () => {
        const newAppearance = getThemeConfig();
        this.elements.update({ appearance: newAppearance });
      });
    },
    async handlePayment() {
      if (this.paymentProccessing == true) {
        console.log("Payment already processing");
        return;
      }
      this.stripeErrors = [];
      this.paymentProccessing = true;
      let paymentSection = document.querySelector(".payment-section");
      paymentSection.style.setProperty("display", "none");
      let elements = this.elements;
      // Confirm the payment
      let email = this.email ? this.email : this.user.email;
      let name = this.name ? this.name : this.user.fname;
      let cell = this.cell ? this.cell : this.user.phone;
      let notes = this.notes ? this.notes : "";
      let returnURL =
        window.location.href +
        "?name=" +
        encodeURIComponent(name) +
        "&email=" +
        encodeURIComponent(email) +
        "&cell=" +
        encodeURIComponent(cell) +
        "&notes=" +
        encodeURIComponent(notes);
      // console.log("retrun url:", returnURL);
      const result = await this.stripe.confirmPayment({
        elements: elements,
        redirect: "if_required",
        confirmParams: {
          return_url: returnURL,
          receipt_email: email,
        },
      });
      if (result.error) {
        // console.log(result.error.message);
        paymentSection.style.setProperty("display", "block");
        this.stripeErrors.push(result.error.message);
      } else {
        if (result.paymentIntent.status === "succeeded") {
          console.log("payment succeeded");
          this.completeTransaction(result);
        }
      }
      this.paymentProccessing = false;
    },
    async search() {
      if (this.searchQuery.length > 1) {
        const search = await api.searchAccounts(this.searchQuery);
        let result = search.rows.map((acc) => (acc.key[2] == "id-name" ? { key: [acc.key[1], acc.key[0]] } : acc));
        //console.log(result);
        this.searches = result;
      } else {
        this.searches = [];
      }
    },
    async voucherSearch() {
      if (this.voucherSearchQuery.length > 1) {
        const search = await api.searchVouchers(this.voucherSearchQuery);
        // console.log(search);
        let result = search.rows.map((acc) => (acc.key[2] == "id-name" ? { key: [acc.key[1], acc.key[0]], value: acc.value } : acc));
        // console.log(result);
        this.searches = result;
      } else {
        this.searches = [];
      }
    },
    selectDelivery(option) {
      // console.log(option);
      if (option) {
        document.querySelectorAll('input[type="radio"]').checked = false;
        document.querySelector("." + option).checked = !document.querySelector("." + option).checked;
        this.delivery = option;
      }
    },
    sendToVoucher(name, id, value, voucherBalance) {
      if (voucherBalance <= 0) {
        this.$notify({
          text: `Voucher had no remaining credit`,
          type: "warn",
          duration: 5000,
        });
        return;
      }
      if (this.payments.find((payment) => payment.id === id)) {
        this.$notify({
          text: `Voucher already used`,
          type: "warn",
          duration: 5000,
        });
        return;
      }
      let newValue = value > voucherBalance ? voucherBalance : value;
      let currAcc = {
        ID: id,
        name: name,
        doc_type: "debit",
        value: newValue,
        date: new Date().toISOString(),
      };
      this.channel.push("voucher:doc", currAcc);
      this.voucherUsed = true;
      this.splitOrderCurrentTotal = 0;
      this.addPayment({ type: "voucher", value: newValue, id: id, name: name });
      this.voucherShow = false;
    },
    checkRequirements() {},
    transactionValidation() {
      //console.log('errors')
      this.errors = [];
      if (this.futurePickup) {
        this.dateValidation();
      }
      if (!this.user.fname) {
        if (this.siteSettings.nameRequired && !this.name) {
          this.errors.push("Name is required");
        }

        if (!this.email) {
          this.errors.push("Email is required");
        } else if (!this.email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) {
          this.errors.push("Email input is invalid");
        }

        const phoneNumber = parsePhoneNumber(this.cell, "NZ");
        if (!this.cell) {
          this.errors.push("Cell Phone is required");
        } else if (validatePhoneNumberLength(this.cell, "NZ") || phoneNumber.isValid() === false) {
          this.errors.push("Cell Phone is invalid");
        }
      }

      if (this.futurePickup) {
        if (!this.location) {
          this.errors.push("Location is required");
        }
      }
      //console.log(this.delivery);
    },
    completeTransaction(reply = false, skipValidation = false) {
      let skippedProducts = false;
      if (skipValidation) {
        this.email = this.$route.query.email;
        this.cell = this.$route.query.cell;
        this.name = this.$route.query.name;
        this.notes = this.$route.query.notes;
        let cart = window.localStorage.getItem("cart");
        console.log(cart);
        // console.log("here", JSON.parse(cart).products);
        // skippedProducts = JSON.parse(cart).products;
      } else {
        this.transactionValidation();
      }
      this.eftposBusy = false;
      if (this.errors.length === 0) {
        this.openModal = false;
        //console.log("Ready to complete transaction");
        //console.log(
        //   "On callback from complete transaction - need to clear the payments and cart"
        // );

        this.channel = this.$.appContext.config.globalProperties.channel;

        let delivery = "online";
        if (delivery == "online" && this.futurePickup) {
          delivery = "future-pickup";
        }
        var now = new Date();
        now.toISOString();

        var order_data = {
          version: 2,
          name: this.user.fname ? this.user.fname : this.name,
          notes: this.notes,
          email: this.user.email ? this.user.email : this.email,
          cell: this.user.phone ? this.user.phone : this.cell,
          location: this.location,
          complete: false,
          paymentStatus: this.remaining === 0 ? "paid" : "partial",
          delivery: delivery,
          products: skippedProducts ? skippedProducts : this.products,
          payments: this.payments,
          pickup_date: this.date,
          total: this.total,
        };
        if (reply) {
          order_data.stripe = reply;
        }
        // console.log(order_data);

        // a different listener can respond to the invoice */

        //n//console.log(this.cartOrder);
        /* send to web socket */
        if (this.cartOrder && this.cartOrder._id) {
          this.cartOrder["events"]["updated_at"] = now.toISOString();

          // this.cartOrder['events']['updated_at'] = now.toISOString()
          let doc = { ...this.cartOrder, ...order_data };
          ////console.log(doc);
          this.channel.push("order:update", doc);
        } else {
          order_data["events"] = { created_at: now.toISOString(), updated_at: now.toISOString() };

          this.channel.push("order:new", order_data).receive("ok", async (reply) => {
            // console.log("reply", reply);
            let orderUser = { ...this.user };
            !orderUser["orders"] ? (orderUser["orders"] = [reply.reply.rows[0].id]) : orderUser["orders"].push(reply.reply.rows[0].id);
            // console.log("emailing", reply);
            localStorage.setItem("orderID", reply.reply.rows[0].id);
            this.channel.push("email:receipt", reply.reply.rows[0].doc);
            if (Object.keys(this.user).length > 0) {
              let res = await api.updateUser(orderUser);
              res = JSON.parse(res);
              orderUser["_rev"] = res.rev;

              this.$store.dispatch("updateUser", orderUser);
            }
          });
        }
        this.updateWebOrder({ order: order_data });
        this.clearCart();
        this.clearPayments();
        this.splitOrderCurrentTotal = 0;
        this.voucherShow = false;
        this.voucherSearchQuery = "";
        if (!skipValidation) {
          this.$emit("showWebOrderModal", true);
        }
        this.paymentProccessing = false;
        if (skipValidation) {
          this.$router.push({ path: window.location.pathname });
          this.$emit("showWebOrderModal", true);
        }
      }
    },
  },

  computed: {
    ...mapGetters({
      siteSettings: "siteSettings",
      payments: "cartPayments",
      products: "cartProducts",
      discounts: "discounts",
      cartOrder: "cartOrder",
      eftposSettings: "eftposSettings",
      orderName: "orderName",
      orderNotes: "orderNotes",
      orderDelivery: "orderDelivery",
      futurePickup: "futurePickup",
      user: "user",
    }),
    surchargeTotal() {
      const total = this.products.reduce((total, p) => {
        return total + p.price * p.quantity;
      }, 0);
      return 0.15 * total;
    },
    ccTotal() {
      const total = this.products.reduce((total, p) => {
        return total + p.price * p.quantity;
      }, 0);
      return 0.03 * total;
    },
    total() {
      let total = this.products.reduce((total, p) => {
        return total + p.price * p.quantity;
      }, 0);
      if (this.siteSettings.surcharge) {
        total += this.surchargeTotal;
      }
      total += this.ccTotal;
      return total;
    },
    paid() {
      return this.payments.reduce((total, p) => {
        return total + p.value;
      }, 0);
    },
    remaining() {
      return this.total - this.paid;
    },
    /* split total is either the remaining total or the current total for items that have been split but not yet paid for */
    splitTotal() {
      if (this.splitOrderCurrentTotal && this.splitOrderCurrentTotal > 0) {
        return this.splitOrderCurrentTotal;
      }
      return this.remaining;
    },
    physicalShops() {
      let shops = {};
      for (let key in this.shops) {
        if (this.shops[key].online) {
          shops[key] = this.shops[key];
        }
      }
      return shops;
    },
  },
  watch: {
    searchQuery: function() {
      this.search();
    },
    voucherSearchQuery: function() {
      this.voucherSearch();
    },
    async open() {
      const search = await api.searchVouchers(this.user.email);
      let result = search.rows.map((acc) => (acc.key[2] == "id-name" ? { key: [acc.key[1], acc.key[0]] } : acc));
      this.searches = result;

      this.openModal = true;
      this.customAmount = 0;
      this.moneyShow = false;
      this.eftposShow = false;
      this.openNotesModal = true;
      this.name = this.orderName != null && this.orderName.length > 0 ? this.orderName : null;
      this.notes = "";
      this.delivery = this.orderDelivery;
      if (this.delivery != null) {
        this.selectDelivery(this.delivery);
      }
    },
    openModal() {
      if (!this.openModal) {
        this.intentLoaded = false;
      }
    },
    close() {
      this.openModal = false;
      this.intentLoaded = false;
    },
  },
};
</script>
<style scoped lang="scss">
@import "public/layout";
@import "public/actions";
@import "public/wrapper";
@import "public/search";

.stripe-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1rem;
  box-shadow: var(--box-shadow);
  /* width: 80%; */
  margin: 1rem auto;
  border-radius: 0.5rem;
  cursor: pointer;
  p {
    margin: 0.5rem;
  }
}

.btn {
  cursor: pointer;
  text-align: center;
  margin: 0.5rem auto;
  font-size: small;
  box-shadow: var(--box-shadow);
}

.remaining {
  display: flex;

  margin-top: 0px;
  border-bottom: 2px solid #ccc;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  text-align: right;

  span.label {
    flex: 0 1 auto;
    display: inline-block;
    text-align: left;
  }

  span.value {
    flex: 1 0;
    display: inline-block;
  }
}

.actions {
  margin-top: 1rem;
}
.log {
  list-style: none;
  text-transform: capitalize;
  padding: 0px;
  text-align: right;
}

.modal {
  .exit {
    font-size: 2rem;
    text-align: center;
    display: block;
    margin: 0.5rem auto;
    cursor: pointer;
    color: var(--action-colour);
  }
  .time {
    color: var(--action-colour);
    font-size: 1.5rem;
    text-align: center;
    display: block;
    margin: 0.5rem auto;
  }
  // .StripeElement {
  //   padding: 1rem;
  // }

  div.outer {
    display: flex;
    background-color: var(--secondary-colour);
    min-width: 50vw;
    max-width: 90vw;
    min-height: 50vh;
    max-height: 90vh;
    padding: 1rem;
    justify-content: space-around;
    align-items: baseline;
    border-radius: 0.5rem;
    flex-direction: row;
    flex-wrap: wrap;
    .bump-right {
      flex: 1 0 100%;
      // left: 50%;
    }
    form {
      display: contents;
    }

    h2 {
      padding-top: 0px;
    }

    div.slide {
      -webkit-transition: display 2s ease-in-out;
      transition: display 2s ease-in-out;
    }

    div.close {
      display: none;
    }

    .details {
      padding: 1rem;
      overflow-y: auto;
      min-width: 90%;
      width: 100%;

      .logged-in {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 10px;
        .logged-in-user {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          margin: 0.5rem 0;
        }
        .user-info {
          margin-left: 1rem;
          font-size: x-large;
        }

        .notes {
          input {
            background-color: var(--primary-colour);
            color: var(--primary-colour);
          }
          .allergies {
            label {
              margin: 0;
              font-size: x-large;
              h4 {
                text-wrap: nowrap;
              }
            }
          }
          div {
            textarea {
              // padding: 0.5rem;
              margin-left: 1rem;
              font-size: small;
              width: 15rem;
              &::placeholder {
                font-size: small;
              }
            }
          }
        }
        div {
          button {
            width: 40rem;
          }
        }
      }

      .svg-spinner {
        background-color: rgba(255, 255, 255, 0.5);
        z-index: 10;
        position: absolute;
        height: 100vh;
        width: 100vw;
        top: 0;
        left: 0;
        .loading-spinner {
          z-index: 100;
          position: absolute;
        }
      }

      h4 {
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
      }

      .notes,
      .email,
      .cell,
      .eftpos,
      .money,
      .location {
        input,
        select {
          font-family: "Lato";
          margin-bottom: 1rem;
          border: 1px solid var(--boder-colour);
          width: calc(100% - 2rem);
          padding: 1rem 1rem 1rem 1rem;
          font-size: 1.5rem;
          height: 2rem;
          border-radius: 0.5rem;
        }
        textarea {
          font-family: "Lato";
          margin-bottom: 1rem;
          border: 1px solid var(--border-colour);
          width: calc(100% - 2rem);
          padding: 1rem 1rem 1rem 1rem;
          font-size: 1.5rem;
          height: 2rem;
          resize: none;
          border-radius: 0.5rem;
        }
        button {
          margin: auto;
          margin-top: 0.5rem;
        }
        .allergies {
          display: flex;
          flex-direction: row;
          font-size: 1.5rem;
          justify-content: flex-start;
          align-items: flex-start;
          font-size: 1.25rem;
        }
      }

      .notes {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
        grid-auto-flow: row;
        grid-column-gap: 1rem;
        .allergies {
          text-wrap: nowrap;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          input {
            width: 2rem;
            margin: 0.5rem;
          }
          textarea {
            padding: 0.5rem;
            margin-left: 1rem;
            width: 14rem;
          }
        }
      }

      .delivery {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        grid-auto-flow: row;
        grid-column-gap: 1rem;
        grid-row-gap: 1rem;

        input {
          appearance: none;

          border-radius: 50%;
          width: 1rem;
          height: 1rem;
          border: 2px solid var(--action-colour);
          margin-right: 0.5rem;
        }
        input:checked {
          background-color: var(--action-colour);
          // border: 0.6rem solid var(--action-colour);
        }
        span {
          text-transform: capitalize;
          display: inline-block;
          padding: 1rem;
          border: 1px solid #ccc !important;
          background-color: var(--primary-colour);
          font-size: 1.4rem;
          border-radius: 0.5rem;
        }
      }

      .discounts {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        grid-auto-flow: row;
        grid-column-gap: 1rem;
        grid-row-gap: 1rem;

        button {
          margin: 0px;
        }
        button:first-child {
          flex: 0.5 1;
          margin-right: 1rem;
        }
        button:nth-child(1) {
          flex: 0.5 1;
        }
        button:last-child {
          flex: 0.5 1;
          margin-right: 0px;
        }
      }

      .payments {
        display: flex;
        gap: 5px;

        .button-spacing {
          display: flex !important;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }
        .pencil-button {
          background-color: var(--secondary-colour);
          border-radius: 0.5rem;
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        button {
          margin: 0px;
        }
      }

      .stripe {
        button {
          margin: 10px 0px;
        }
        margin-top: 1rem;
      }
    }
    .errors {
      color: var(--warning-colour);
      margin: 1rem;
      font-size: 1.5rem;
    }

    div.cart {
      flex: 0 1;
      padding: 0.5rem 0.5rem 0.5rem 0.5rem;
      min-width: 250px;
    }
  }
}

@media only screen and (max-width: 995px) {
  .cust-info {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)) !important;
  }
}

@media screen and (orientation: landscape) {
  .outer {
    flex-direction: row;
  }
}

@media screen and (orientation: portrait) {
  .modal {
    .outer {
      flex-direction: column-reverse;
    }

    & > div {
      display: flex;
      flex-direction: column;
      background-color: var(--primary-colour);
      width: 100%;
      padding: 1rem;
      overflow-y: scroll;
    }

    div.outer {
      form {
        // display: block;
        margin: 0;
        div.details {
          // flex: 0;
          .logged-in {
            .logged-in-user {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;
              gap: 10px;
            }
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 10px;
            div {
              button {
                width: 20rem;
                margin-left: 0;
              }
            }
            .notes {
              width: 100%;
            }
          }

          .notes,
          .email,
          .cell,
          .location {
            input,
            select {
              font-size: 1rem;
            }
            textarea {
              font-size: 1rem;
            }

            .notes {
              display: grid;
              grid-template-areas:
                "cart"
                "cust-info";
            }
            grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
          }
        }
      }
      div.cart {
        min-width: inherit;
      }
      .payments {
        display: grid !important;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      }
    }
  }
}

h2 {
  margin-top: 0px;
  padding-top: 1rem;
}

button {
  padding: 1rem;
  width: 100%;
  font-size: 1.5rem;

  &.is-primary {
    margin-bottom: 1rem;
  }
}

#showmethemoney {
  height: 80%;
  width: 100%;
  text-align: center;
}

.money,
.eftpos {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-auto-flow: row;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  flex-wrap: wrap;
  justify-content: stretch;

  select.dollarBills {
    height: 80%;
    width: 100%;
  }

  button {
    margin-left: 0px;
  }
}

.no-money,
.no-eftpos,
.no-account {
  display: none;
}
</style>
