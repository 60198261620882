<template>
  <div class="cart history">
    <DualButton :show="showOrderModal" :order="currentOrder" @email="emailOrder" @load="loadOrder" @print="printOrder" />
    <ul class="grid wrapper" id="history" ref="history">
      <HistoryOrder
        :order="order.value"
        @click="
          currentOrder = order.value;
          showOrderModal = !showOrderModal;
        "
        v-bind:key="order"
        v-for="order in history" />
        
        
    </ul>
    
  </div>
</template>

<script>
import DualButton from "@/components/History/DualButton";
import { mapActions, mapGetters } from "vuex";
import createdMixin from "@/components/_mixins/createdMixin";
import HistoryOrder from "@/components/History/HistoryOrder";

export default {
  name: "HistoryColumn",
  mixins: [createdMixin],
  components: {
    HistoryOrder,
    DualButton,
  },

  data() {
    return {
      channel: null,
      currentOrder: null,
      showOrderModal: false,
      tableMode: false,
    };
  },
  mounted() {
    document.querySelector("div.parent").addEventListener("scroll", this.debounce(this.onScroll, 300), { passive: true });
  },
  beforeUnmount() {
    document.removeEventListener("scroll", this.onScroll);
  },

  methods: {
    onScroll() {
      var history = this.$refs["history"];
      // console.log(history.getBoundingClientRect().bottom, window.innerHeight);
      if (history) {
        var marginTopUsers = history.getBoundingClientRect().bottom;
        var innerHeight = window.innerHeight;
        // //console.log(innerHeight, marginTopUsers, innerHeight - Math.abs(marginTopUsers));
        if (innerHeight > Math.abs(marginTopUsers)) {
          this.appendHistory();
        }
      }
    },
    debounce(fn, wait) {
      let timer;
      return function (...args) {
        if (timer) {
          clearTimeout(timer); // clear any pre-existing timer
        }
        const context = this; // get the current context
        timer = setTimeout(() => {
          fn.apply(context, args); // call the function if time expires
        }, wait);
      };
    },
    loadOrder(order) {
      // console.log(order);
      this.$store.dispatch("initialiseCartFromHistory", order);
      this.$emit("showTab", "cart");
    },

    emailOrder(order, to_address) {
      order.email = to_address;
      //let from_address = "no-reply@beamy.nz";
      ////console.log('Email receipt placeholder', order);
      this.channel.push("email:receipt", order).receive("ok", (reply) => {
        if ("error" in reply) {
          this.$notify({
            text: "Email Failed to Send",
            type: "error",
          });
          return;
        } else if ("reply" in reply) {
          this.$notify({
            text: "Email Sent",
            type: "success",
          });
        }
      });
    },

    printOrder(order) {
      //console.log(order)
      var products;
      var eol = "\r\n";
      let receipt = {};
      const cc = 10; // character count(width of eftpos printer)
      const reducer = (acc, value) => acc + value;
      const padStringQuantity = (name, quantity, number) =>
        name.length > cc ? name.substring(0, 19) + eol + "X" + quantity + " $" + number.toFixed(2) + eol : name.padEnd(15 - name.length, " ") + "X" + quantity + " $" + number.toFixed(2) + eol;
      const padString = (name, number) => (name.length > 14 ? name.substring(0, 13) + " $" + number.toFixed(2) + eol : name.padEnd(16 - name.length, " ") + " $" + number.toFixed(2) + eol);

      var address = this.siteSettings.address.replace(/,/g, eol);

      if (order.payments && order.payments.length !== 0) {
        ////console.log(order.payments);
        products = order.products
          .map((item) => padStringQuantity(item.item, item.quantity, item.price))
          .toString()
          .replace(/,/g, "");
        var paid = padString("Paid", order.payments.map((item) => item.value).reduce(reducer));
        receipt = this.siteSettings.company_name + eol + address + eol + eol + "G.S.T. #" + this.siteSettings.gst + eol + eol + products + eol + paid + eol;
      } else {
        var total = padString("Total", order.products.map((item) => item.quantity * item.price).reduce(reducer));
        products = order.products
          .map((item) => padStringQuantity(item.item, item.quantity, item.price))
          .toString()
          .replace(/,/g, "");
        receipt = this.siteSettings.company_name + eol + address + eol + eol + "G.S.T. #" + this.siteSettings.gst + eol + eol + products + eol + total + eol;
      }
      //console.log(receipt);
      //var receipt_example = "Beam Me Up Bagels\r\n*------------------*\r\n\r\nADULT\t$100.00\r\nGEAR RENTA\t$35.00\r\n\r\nTOTAL $135.00\r\nincl GST $17.61\r\n*------------------*\r\nTHANKS FOR COMING\r\n";
      if (window.Android && window.Android.printReceipt) {
        window.Android.printReceipt(receipt, this.eftposSettings[0].ip, parseInt(this.eftposSettings[0].port));
      } else {
        //console.log('Tell user to use android');
      }
    },
    ...mapActions(["appendHistory"]),
  },

  computed: {
    currentCategoryDoc() {
      return this.categories.find((cat) => cat.id === this.currentCategory);
    },
    ...mapGetters({
      history: "history",
      eftposSettings: "eftposSettings",
      siteSettings: "siteSettings",
      localSettings: "localSettings",
      products: "cartProducts",
    }),
  },
};
</script>

<style scoped lang="scss">
@import "public/layout";
@import "public/wrapper";
@import "public/actions";

h2 {
  margin-top: 0px;
  padding-top: 1rem;
  span:nth-of-type(1) {
    color: #aaa;
  }
  cursor: pointer;
}

ul.wrapper {
  grid-template-columns: repeat(1, minmax(0, 1fr));
  margin: 0.5rem;
  padding: 0.5rem;
  .modifierBox {
    background-color: var(--primary-colour);

    .modifier-total {
      display: block;
      font-weight: bold;
      text-align: right;
    }
    li.lineItem {
      margin-bottom: 1rem !important;

      .lineItemSummary {
        display: flex;
        .lineItemName {
          flex: 1 1;
          display: inline-block;
          text-align: left;
        }
        .lineItemTotal {
          flex: 0 0;
          display: inline-block;
          text-align: right;
        }
      }
    }

    ul.modifiers {
      border-bottom: 1px solid #ccc;
      font-size: 0.8em;
      li {
        list-style-type: none;
        display: flex;
        .submodifier-name {
          flex: 1 1;
          display: inline-block;
        }
        .submodifier-total {
          flex: 0 0;
          display: inline-block;
          text-align: right;
        }
      }

      margin: 0px;
      padding: 0px;
    }
  }
}
</style>
