<template>
  <div class="allergens">
    <section class="allergen-list">
      <p>Allergies:&nbsp;</p>
      <p v-for="allergen in currentAllergens" :key="allergen">{{ allergen.name }},&nbsp;</p>
    </section>
    <details>
      <summary>Add allergen</summary>
      <section class="grid">
        <div v-for="(allergen, idx) in addAllergens" :key="idx" class="container">
          <p>{{ allergen.name }}</p>
          <ToggleSwitch :modelValue="allergen.contains" :modelName="allergen.name" @update:modelValue="emitAllergen(allergen.name, $event)" />
        </div>
      </section>
    </details>
  </div>
</template>

<script>
import ToggleSwitch from "@/components/_layouts/ToggleSwitch.vue";

export default {
  name: "AllergenList",
  components: {
    ToggleSwitch,
  },

  emits: ["allergenChanged"],
  data() {
    return {
      addAllergens: [
        { name: "Celery", contains: false },
        { name: "Gluten", contains: false },
        { name: "Crustacean", contains: false },
        { name: "Egg", contains: false },
        { name: "Fish", contains: false },
        { name: "Lupin", contains: false },
        { name: "Milk", contains: false },
        { name: "Mollusc", contains: false },
        { name: "Mustard", contains: false },
        { name: "Tree Nut", contains: false },
        { name: "Peanut", contains: false },
        { name: "Sesame Seed", contains: false },
        { name: "Soya", contains: false },
        { name: "Sulphur Dioxide", contains: false },
      ],
    };
  },
  methods: {
    emitAllergen(allergen, contains) {
      this.addAllergens.forEach((all) => {
        if (all.name === allergen) {
          all.contains = contains;
        }
      });
      return this.$emit("allergenChanged", this.addAllergens);
    },
  },
  computed: {
    currentAllergens() {
      let removedAllergen = this.addAllergens.filter((allergen) => allergen.contains);
      return removedAllergen;
    },
  },
  mounted() {
    // Lifecycle hook when the component is mounted
  },
};
</script>

<style scoped>
.allergen-list {
  display: flex;
  flex-direction: column;
}

.allergen-list {
  display: flex;
  flex-direction: row;
}

summary {
  margin: 0.5rem 0;
  background-color: var(--primary-colour);
  padding: 1rem;
  border-radius: 5px;
  box-shadow: var(--box-shadow);
  cursor: pointer;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-template-rows: repeat(auto-fill, minmax(100px, 1fr));
  gap: 0.5rem;
  background-color: var(--primary-colour);
  border-radius: 10px;
  padding: 0.25rem;
  margin: 0.5rem;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0.5rem;
  background-color: var(--secondary-colour);
  box-shadow: var(--box-shadow);
  border-radius: 10px;
  padding: 0.5rem;
}


@media only screen and (orientation: portrait) {
  .grid {
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    grid-auto-flow: row;
    padding: 1rem;
    justify-items: center;
  }

  .container {
    width: 90%;
  }
}
</style>
