<template>
  <div class="parent" :class="{ closed: getClosed }">
    <div class="info">
      <div v-if="!getClosed">
        <img :src="shopLogo" />
        <h2>{{ shopName }}</h2>
        <p>{{ shopDesc }}</p>
        <p>We close at {{ getTodayHours.closeTime }}</p>
      </div>
    </div>
    <div v-if="getClosed">
      <h1>Site is currently closed</h1>
      We open again at {{  getTodayHours.openTime }}
    </div>
    <div class="info-end">
      <Theme />
      <div class="user-icon" @click="accountOpen">
        <font-awesome-icon :class="user.fname ? 'active icon' : 'icon'" icon="user" />
        <div v-if="user.fname">Hi, {{ user.fname }}</div>
        <div v-if="!user.fname">Sign In</div>
      </div>
    </div>
  </div>
  <UserModal :open="showUserModal" />
  <UserAccountModal :open="showUserAccountModal" :account="user" v-if="user.fname" />
</template>

<script>
import { mapGetters } from "vuex";
import UserModal from "../User/UserModal.vue";
import UserAccountModal from "../User/UserAccountModal.vue";
import Theme from "./Theme.vue";

export default {
  data() {
    return {
      companyName: window.localStorage.getItem("companyName"),
      sites: JSON.parse(window.localStorage.getItem("sites")),
      site: window.localStorage.getItem("site"),
      showUserModal: false,
      showUserAccountModal: false,
      shops: JSON.parse(window.localStorage.getItem("shops")),
      days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    };
  },
  components: {
    UserModal,
    UserAccountModal,
    Theme,
  },
  watch: {
    user: {
      handler() {
        if (Object.keys(this.user).length > 0 && this.user.settings.theme) {
          document.documentElement.classList.add(`${this.user.settings.theme}-theme`);
        } else {
          document.documentElement.classList.remove(`os-theme`);
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      getClosed: "getClosed",
      user: "user",
      siteSettings: "siteSettings",
      getTodayHours: "getTodayHours",
      currentTime: "getCurrentTime",
    }),
    shopName() {
      let name;
      for (let shop in this.shops) {
        if (shop === this.site) {
          name = this.shops[shop].site_name;
        }
      }
      return name;
    },
    shopDesc() {
      let desc;
      for (let shop in this.shops) {
        if (shop === this.site) {
          desc = this.shops[shop].site_description;
        }
      }
      return desc;
    },
    shopLogo() {
      let desc;
      for (let shop in this.shops) {
        if (shop === this.site) {
          desc = this.shops[shop].logo;
        }
      }
      return desc;
    },
    // countdownTimer() {
    //   let compareDay = new Date();
    //   let ct = this.getTodayHours.closeTime.split(":");
    //   compareDay.setHours(ct[0], ct[1], 0, 0);
    //   return compareDay;
    // },
  },
  methods: {
    accountOpen() {
      Object.keys(this.user).length > 0 ? (this.showUserAccountModal = !this.showUserAccountModal) : (this.showUserModal = !this.showUserModal);
    },
  },
};
</script>
<style scoped lang="scss">
.closed {
  background-color: var(--warning-colour);
}

.parent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1rem;

  .info {
    display: flex;
    flex-direction: column;
    gap: 5px;
    img {
      max-width: 15rem;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
    h2 {
      margin: 0;
    }

    p {
      margin: 0;
      text-decoration: none;
    }
  }
  .info-end {
    display: flex;
    .user-icon {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin: 0 0 0 2rem;
      .active {
        color: var(--action-colour);
      }
      &:hover {
        color: var(--action-colour);
      }
      .icon {
        height: 2.5rem;
      }
    }
  }
}

.break {
  flex-basis: 100%;
  height: 0;
}
</style>
