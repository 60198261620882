<template>
  <div class="parent" v-show="webCart">
    <div class="cart">
      <div class="exit-container">
        <font-awesome-icon icon="xmark" class="exit" @click="webCart = false" />
      </div>
      <h2 class="title">
        <span>Cart</span>
      </h2>
      <Cart v-if="showCart" @showWebOrderModal="openWebOrder = !openWebOrder" @showCheckoutModal="openCheckout = !openCheckout" @loadOrderLineItem="sendItem" />

      <HistoryColumn v-if="!showCart" @showTab="showTab" />
    </div>
  </div>
  <CheckoutModal ref="checkoutChild" @showWebOrderModal="openWebOrder = !openWebOrder" :open="openCheckout" />
  <div class="cart-icon" @click="topScroll">
    <div class="cart-count">{{ cartCount }}</div>

    <font-awesome-icon class="icon" icon="cart-shopping" />
  </div>

  <WebOrderModal :open="openWebOrder" />
</template>
<script>
import { mapGetters } from "vuex";

import HistoryColumn from "../History/HistoryColumn.vue";
import Cart from "./Cart.vue";
import CheckoutModal from "./CheckoutModal.vue";
import WebOrderModal from "./WebOrderModal.vue";

export default {
  name: "CartColumn",
  components: {
    HistoryColumn,
    Cart,
    CheckoutModal,
    WebOrderModal,
  },

  mounted() {
    if (this.open) {
      this.openCheckout = this.checkoutOpen;
    }
  },

  computed: {
    ...mapGetters({
      cartCount: "cartCount",
      socketConnected: "socketConnected",
      products: "cartProducts",
    }),
  },
  emits: ["loadOrderLineItem"],
  props: ["open", "checkoutOpen"],
  data() {
    return {
      showCart: true,
      openCheckout: false,
      webCart: false,
      portrait: false,
      openWebOrder: false,
    };
  },
  methods: {
    sendItem(item) {
      this.$emit("loadOrderLineItem", item);
    },

    showTab(column) {
      column === "cart" ? (this.showCart = true) : false;
    },

    topScroll() {
      window.scrollTo({ top: 0, behavior: "smooth" });
      ////console.log(window.screenTop);
      if (window.screenTop < window.innerHeight) {
        this.webCart = !this.webCart;
      } else {
        this.webCart = true;
      }
      this.$store.dispatch("setCurrentTime", new Date());
    },

    redirect() {
      if ("redirect_status" in this.$route.query) {
        if (this.$route.query.redirect_status == "succeeded") {
          this.$refs.checkoutChild.completeTransaction(false, true);
          this.$notify({
            text: "Payment Succeeded.",
            type: "success",
            duration: 1500,
          });
        } else {
          this.$notify({
            text: "Payment Failed.",
            type: "error",
            duration: 1500,
          });
        }
      }
    },
  },
  watch: {
    products() {
      if (this.products.length > 0 && this.socketConnected) {
        // console.log("called redirect");
        this.redirect();
      }
    },
  },
};
</script>
<style scoped lang="scss">
// .parent {
//   display: flex;
//   flex-direction: column;

//   padding: 0rem 1rem 0rem 1rem;
//   background-color: var(--secondary-colour);
//   width: 20rem;
//   border-radius: 1rem 0rem 0rem 0rem;
//   .title {
//     .active {
//       color: var(--action-colour);
//     }
//     .dull {
//       color: var(--text-colour);
//     }
//   }
// }



.cart-icon {
  display: none;
}
.parent {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 10000;
  .cart {
    margin-top: 1rem;
    width: 70%;
    position: static;
    border-radius: 20px;
    background-color: var(--primary-colour);
    box-shadow: var(--box-shadow);
    padding: 2rem;
    z-index: 11000;
    .exit-container {
      width: 100%;
      display: flex;
      justify-content: center;
      .exit {
        padding: 0;
        font-size: 2rem;
        color: var(--action-colour);
        cursor: pointer;
      }
    }
  }
}
.cart-icon {
  position: fixed;
  top: auto;
  bottom: 1rem;
  right: 1rem;
  color: var(--action-colour);
  cursor: pointer;
  background-color: var(--secondary-colour);
  border: 0.25rem solid var(--primary-colour);
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;

  .icon {
    width: 2rem;
  }
  .cart-count {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: var(--warning-colour);
    color: var(--secondary-text-colour);
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
  }
}
</style>
