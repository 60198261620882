<template>
  <teleport to="body">
    <div class="modal" v-if="showUserAccountModal" @click.self.prevent="showUserAccountModal != showUserAccountModal">
      <div class="wrapper">
        <div class="content">
          <div class="header">
            <div class="accDetails">
              <h1>{{ account.fname }} {{ account.lname }}</h1>
            </div>
            <font-awesome-icon @click="edit = !edit" class="editIcon" icon="gear" />
          </div>
          <div v-if="!edit">
            {{ account.email }}<br />
            {{ account.phone }}

            <div class="prev-orders">
              <h2>Previous Orders</h2>
              <div class="order">
                <div class="order-details">
                  <p v-if="!order">Place an order at this location to see previous orders!</p>
                  <ul v-if="loaded" class="grid wrapper account" id="history" ref="orders">
                    <template v-for="prod in order" v-bind:key="prod">
                      <div v-if="prod.docs[0].ok" class="modifierBox">
                        <h4>{{ formattedDate(prod.docs[0].ok.events.created_at) }}</h4>
                        <div class="item">
                          <div v-for="item in prod.docs[0].ok.products" :key="item">
                            {{ item.name }}
                          </div>
                        </div>
                        <div class="email" @click="emailOrder(prod.docs[0].ok)">
                          <font-awesome-icon icon="envelope" />
                          Email Receipt
                        </div>
                      </div>
                    </template>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="edit" class="edit">
          <form autocomplete="off" @submit.prevent>
            <div class="details">
              <div>
                <input type="email" v-model="updatedUserData.email" placeholder="Email..." />
                <input type="tel" v-model="updatedUserData.phone" placeholder="Cell Phone..." />
              </div>

            </div>
          </form>
        </div>

        <div class="actions" v-if="!edit">
          <button class="action cancel" @click.prevent.stop="showUserAccountModal = false">Exit</button>
          <button class="action primary" @click.prevent.stop="signOut">Sign Out</button>
        </div>
        <div class="actions" v-if="edit">
          <button class="action cancel" @click.prevent.stop="edit = false">Cancel</button>
          <button class="action primary" @click.prevent.stop="confirmModal(false)">Update</button>
        </div>
      </div>
    </div>
  </teleport>
  <Confirm :open="openConfirm" :message="'Are you sure you want to update your account'" @confirmModal="confirmModal" />
  <DualButton :show="showOrderModal" :order="currentOrder" @email="emailOrder" :account="true" />
</template>
<script>
import { api } from "@/helpers/api";
import Confirm from "../Store/Confirm.vue";
import DualButton from "../History/DualButton.vue";
import data from "./data.json";
import { mapGetters } from "vuex";

export default {
  name: "UserAccountModal",
  props: ["open", "account"],
  components: {
    Confirm,
    DualButton,
  },
  data() {
    return {
      showUserAccountModal: false,
      edit: false,
      test: data,
      loaded: false,
      order: null,
      showOrderModal: false,
      currentOrder: null,
      updatedUserData: this.account,
      openConfirm: false,
    };
  },
  watch: {
    async open() {
      this.showUserAccountModal = true;
      if (this.account.orders) {
        const doc = this.account.orders.map((id) => {
          return {
            id: id,
          };
        });
        const res = await api.getUserOrders(doc);
        this.order = res.results.reverse();
        this.loaded = true;
      }
    },
    edit() {
      this.updatedUserData.email = this.account.email;
      this.updatedUserData.phone = this.account.phone;
    },
  },
  computed: {
    ...mapGetters({
      products: "allProducts",
      modifiers: "modifiers",
    }),
  },
  methods: {
    formattedDate(date) {
      return new Date(date).toLocaleString(navigator.languages[0], { timeZone: "Pacific/Auckland" });
    },
    emailOrder(order) {
      order.email = this.account.email;
      this.channel.push("email:receipt", order).receive("ok", (reply) => {
        if ("error" in reply) {
          this.$notify({
            text: "Email Failed to Send",
            type: "error",
          });
          return;
        } else if ("reply" in reply) {
          this.$notify({
            text: "Email Sent",
            type: "success",
          });
        }
      });
    },
    confirmModal(action) {
      if (action) {
        this.update();
      }
      this.showUserAccountModal = !this.showUserAccountModal;
      this.openConfirm = !this.openConfirm;
    },
    themeUpdate(theme) {
      this.updatedUserData.settings.theme = theme;
    },
    async update() {
      const updatedUser = this.$store.getters.user;

      // if (JSON.stringify(updatedUser) === JSON.stringify(this.updatedUserData)) {
      //   this.$notify({
      //     text: "<span style='font-size:16px'>Nothing to be updated</span>",
      //     type: "warning",
      //     duration: 1500,
      //   });
      //   return;
      // }
      for (const key in this.updatedUserData) {
        if (this.updatedUserData[key].length > 0 && this.updatedUserData[key] != this.account[key]) {
          updatedUser[key] = this.updatedUserData[key];
        }
      }

      const res = await api.updateUser(updatedUser);
      const jsonRes = JSON.parse(res);
      updatedUser._rev = jsonRes.rev;
      await this.$store.commit("updateUserMutation", updatedUser);

      if (jsonRes.ok) {
        this.edit = false;

        this.$notify({
          text: "Account Updated",
          type: "success",
          duration: 1500,
        });
        // this.$store.commit("setUserMutation");
      }
    },
    signOut() {
      window.localStorage.removeItem("userID");
      this.$store.commit("setUserMutation");
    },
  },
};
</script>
<style scoped lang="scss">
@import "public/layout";
@import "public/actions";
@import "public/wrapper";
@import "public/toggle";
@import "public/search";

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  .editIcon {
    padding: 0.5rem;
    &:hover {
      color: var(--action-colour);
      cursor: pointer;
    }
  }

  .accDetails {
    p,
    h1 {
      margin: 0;
    }
  }
}

.order {
  height: 500px;
  overflow-y: scroll;
}

.email {
  margin-top: 1rem;
  color: var(--action-colour);
}

.item {
  text-align: left;
  align-self: baseline;
}
</style>
